<template>
  <div class="p-grid crud-demo">
    <Loader v-model="loading" />
    <div class="p-col-12">
      <div class="card">
        <Toast />
        <h2>Ticket Tag</h2>
        <!-- Toolbar -->
        <Toolbar class="p-mb-4">
          <template v-slot:left>
            <Button
              label="Nuevo"
              icon="pi pi-plus"
              class="p-button-success p-mr-2"
              @click="openNew"
            />
            <Button
              label="Recargar"
              icon="pi pi-refresh"
              class="p-button-primary p-mr-2"
              @click="refresh"
            />
          </template>

          <template v-slot:right>
            <FileUpload
              v-tooltip.bottom="
                `Para importar favor de crear un excel con las columnas <br> 
                 <ol>
                    <li>Nombre</li>
                </ol>`
              "
              mode="basic"
              accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
              :maxFileSize="1000000"
              label="Importar"
              :url="uploadURL"
              @beforeUpload="loading = true"
              @upload="refresh"
              @error="uploadError"
              chooseLabel="Importar"
              class="p-mr-2 p-d-inline-block"
            />
            <Button
              label="Exportar"
              icon="pi pi-upload"
              class="p-button-help"
              @click="exportCSV($event)"
            />
          </template>
        </Toolbar>
        <!-- Datatable -->
        <DataTable
          ref="dt"
          :value="entities"
          dataKey="id"
          :paginator="true"
          :rows="10"
          :filters="filters"
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          :rowsPerPageOptions="[5, 10, 25]"
          currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords} tags"
          responsiveLayout="scroll"
        >
          <template #header>
            <div
              class="table-header p-d-flex p-flex-column p-flex-md-row p-jc-md-between p-ai-start p-ai-md-center"
            >
              <h5 class="p-mb-2 p-m-md-0">Administrar Tags</h5>
              <span class="p-input-icon-left">
                <i class="pi pi-search" />
                <InputText v-model="filters['global'].value" placeholder="Buscar..." />
              </span>
            </div>
          </template>
          <Column
            v-for="header in headers"
            :key="header.value"
            :field="header.value"
            :header="header.name"
            :sortable="true"
          >
            <template #body="slotProps">
              <span class="p-column-title">{{ header.name }}</span>
              {{ slotProps.data[header.value] }}
            </template>
          </Column>
          <Column style="align-items: end">
            <template #body="slotProps">
              <Button
                icon="pi pi-pencil"
                class="p-button-rounded p-button-success p-mr-2"
                @click="edit(slotProps.data)"
              />
              <Button
                icon="pi pi-eye"
                class="p-button-rounded p-button-primary p-mr-2"
                @click="view(slotProps.data)"
              />
              <Button
                icon="pi pi-trash"
                class="p-button-rounded p-button-danger"
                @click="confirmDelete(slotProps.data)"
              />
            </template>
          </Column>
        </DataTable>
        <!-- New and edit dialog -->
        <Dialog
          v-model:visible="newDialog"
          :style="{ width: '450px' }"
          :header="!entity.id ? 'Nuevo Tag' : 'Editar Tag'"
          :modal="true"
          class="p-fluid"
        >
          <div class="p-field">
            <label for="name">Tag</label>
            <InputText
              id="name"
              v-model.trim="entity.name"
              required="true"
              autofocus
              :class="{ 'p-invalid': validate.validations.name === false }"
            />
            <small class="p-invalid" v-if="validate.validations.name === false">
              Favor de llenar el campo de nombre
            </small>
          </div>
          <div class="p-field">
            <label for="color">Color</label>
            <br />
            <ColorPicker
              v-model="entity.color"
              required="true"
              :class="{ 'p-invalid': validate.validations.color === false }"
            /><br />
            <small class="p-invalid" v-if="validate.validations.color === false">
              Favor de elegir un color
            </small>
          </div>
          <template #footer>
            <Button
              label="Cancelar"
              icon="pi pi-times"
              class="p-button-text"
              @click="hideDialog"
            />
            <Button
              label="Guardar"
              icon="pi pi-check"
              class="p-button-text"
              :loading="loading"
              @click="save"
            />
          </template>
        </Dialog>
        <!-- Delete dialog -->
        <Dialog
          v-model:visible="deleteDialog"
          :style="{ width: '450px' }"
          header="Confirmar"
          :modal="true"
        >
          <div class="confirmation-content">
            <i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 2rem" />
            <span v-if="entity"> Estas seguro de eliminar el registro? </span>
          </div>
          <template #footer>
            <Button
              label="No"
              icon="pi pi-times"
              class="p-button-text"
              @click="deleteDialog = false"
            />
            <Button
              label="Si"
              icon="pi pi-check"
              class="p-button-text"
              @click="deleteEntity"
            />
          </template>
        </Dialog>
      </div>
    </div>
  </div>
</template>

<script>
import { TMTicketTag } from "../../../models/tickets/TMTicketTag";
import { FilterMatchMode } from "primevue/api";
import {
  HeaderGrid,
  Rule,
  validate,
  fillObject,
  Toast,
  ErrorToast,
} from "../../../utilities/General";
import Loader from "../../../components/general/Loader.vue";
import Session from "../../../mixins/sessionMixin";
export default {
  mixins: [Session],
  data() {
    return {
      entity: null,
      entities: [],
      //* URL para subir el importador
      uploadURL: null,
      newDialog: false,
      deleteDialog: false,
      filters: {},
      //* Aqui agregamos las reglas de validacion, si solo agregamos el nombre sera
      //* 'required' de tipo 'text'
      rules: [new Rule({ name: "name" }), new Rule({ name: "color" })],
      //* Aqui agregamos el estatus de la validacion, inicializar en las props 'null'
      //* y el valid general en false (por que no esta validado aun)
      validate: {
        valid: false,
        validations: {
          name: null,
          color: null,
        },
      },
      //* Headers donde pondremos el nombre y el valor del datatable
      headers: [new HeaderGrid("Nombre", "name"), new HeaderGrid("Color", "color")],
      loading: false,
    };
  },
  components: { Loader },
  created() {
    this.entity = new TMTicketTag(this.session);
    this.uploadURL =
      this.$config.api_route +
      "tm/TicketTag/Import/" +
      this.session.empresa +
      "/" +
      this.session.sucursal +
      "/" +
      this.session.usuario;
    this.initFilters();
  },
  async mounted() {
    await this.refresh();
  },
  methods: {
    openNew() {
      this.entity = new TMTicketTag(this.session);
      this.newDialog = true;
    },
    hideDialog() {
      this.newDialog = false;
    },
    async save() {
      try {
        //* Validacion de form
        this.loading = true;
        this.validate = validate(this.entity, this.rules);
        if (!this.validate.valid) {
          throw "Favor de validar los campos";
        }
        //* Si el id es != 0 entonces actualizamos, si no, guardamos
        if (this.entity.id && this.entity.id > 0) {
          //* Actualizamos
          let entity = await this.entity.update();
          this.$toast.add({
            severity: "success",
            summary: "Actualizar",
            detail: "Informacion actualizada con exito",
            life: 3000,
          });
          //* Modificamos el listado pah
          let index = this.entities.findIndex((x) => x.id == this.entity.id);
          this.entities[index] = entity;
        } else {
          //* Creamos uno nuevo
          let entity = await this.entity.save();
          //* Agregamos un dato extra

          this.entities.push(entity);
          this.$toast.add(
            new Toast({
              summary: "Creacion",
              detail: "Informacion guardada con exito",
            })
          );
        }
        this.entity = new TMTicketTag(this.session);
        this.newDialog = false;
      } catch (error) {
        this.$toast.add(new ErrorToast(error));
      } finally {
        this.loading = false;
      }
    },
    edit(entity) {
      this.entity = fillObject(this.entity, entity);
      this.newDialog = true;
    },
    confirmDelete(entity) {
      this.entity = fillObject(this.entity, entity);
      this.deleteDialog = true;
    },
    async deleteEntity() {
      try {
        this.loading = true;
        //* Eliminamos de la base
        await this.entity.delete();
        //* Eliminamos de la vista
        this.entities = this.entities.filter((val) => val.id !== this.entity.id);
        this.deleteDialog = false;
        //* Limpiamos la entidad
        this.entity = new TMTicketTag(this.session);
        this.$toast.add({
          severity: "success",
          summary: "Eliminacion",
          detail: "Registro eliminado con exito",
          life: 3000,
        });
      } catch (error) {
        this.$toast.add(new ErrorToast(error));
      } finally {
        this.loading = false;
      }
    },
    exportCSV() {
      this.$refs.dt.exportCSV();
    },
    initFilters() {
      this.filters = {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      };
    },
    uploadError() {
      this.$toast.add(new ErrorToast("Error al intentar importar archivo"));
    },
    async refresh() {
      this.loading = true;
      try {
        this.entities = await this.entity.all();
      } catch (error) {
        this.$toast.add(new ErrorToast(error));
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style scoped lang="scss">
.table-header {
  display: flex;
  justify-content: space-between;
}

.confirmation-content {
  display: flex;
  align-items: center;
  justify-content: center;
}

@media screen and (max-width: 960px) {
  ::v-deep(.p-toolbar) {
    flex-wrap: wrap;

    .p-button {
      margin-bottom: 0.25rem;
    }
  }
}
</style>
