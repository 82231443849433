import {
    Abstract
} from './Abstract';

export class TMTicketTag extends Abstract {
    constructor(session) {
        super('tm/TicketTag', session);
        this.name = null;
        this.color = null;
    }
}
